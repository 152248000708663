import type { HighlightI, IAdditionalFilter, ICategory, IFilterResponse, IRequest } from '@Models/Item/filter.model';

export function fetchItemCategories() {
  const { $lkItemBackFetch } = useNuxtApp();

  return $lkItemBackFetch<ICategory[]>('/rest/item/category', {
    method: 'GET'
  });
}

export function fetchAdditionalParams(itemCategory: number) {
  const { $lkItemBackFetch } = useNuxtApp();

  return $lkItemBackFetch<IAdditionalFilter[]>(`/rest/item/params/category/${itemCategory}`, {
    method: 'GET'
  });
}

export function searchItemsService(data: IRequest, signal?: AbortSignal) {
  const { $lkItemBackFetch } = useNuxtApp();

  return $lkItemBackFetch<IFilterResponse>('/rest/item/search', {
    method: 'POST',
    body: data,
    signal
  });
}

export function highlightsService(query: string) {
  const { $lkItemBackFetch } = useNuxtApp();

  return $lkItemBackFetch<HighlightI[]>('/rest/item/suggestExpression', {
    method: 'GET',
    query: {
      query
    }
  });
}
